/*.ui.tabular.menu .item {
    color: rgba(0, 0, 0, 0.8)
}*/

.leftStyles {
    display: flex;
    flex-direction: column;
    width: 250px;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
    .leftStyles {
        width: 65px;
    }
}